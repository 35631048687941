export const OFFERS_CONFIG = {
    "au-4m3b6q70": {
        "coupon": "9x7KYM2t",
        "currency": "aud",
        "planName": "starter_yearly_nov22_s"
    },
    "au-4m3b6q71": {
        "coupon": "xGpmU125",
        "currency": "aud",
        "planName": "pro_yearly_sep23_s"
    },
    "au-jvhAlk6a0": {
        "coupon": "jvhAlk6a",
        "currency": "aud",
        "planName": "starter_yearly_nov22_s"
    },
    "au-k79uxgRQ1": {
        "coupon": "k79uxgRQ",
        "currency": "aud",
        "planName": "pro_yearly_sep23_s"
    },
    "br-7qa67l0": {
        "coupon": "9x7KYM2t",
        "currency": "brl",
        "planName": "starter_yearly_nov22_s"
    },
    "br-7qa67l1": {
        "coupon": "xGpmU125",
        "currency": "brl",
        "planName": "pro_yearly_sep23_s"
    },
    "ca-8p1t5k90": {
        "coupon": "9x7KYM2t",
        "currency": "cad",
        "planName": "starter_yearly_nov22_s"
    },
    "ca-8p1t5k91": {
        "coupon": "xGpmU125",
        "currency": "cad",
        "planName": "pro_yearly_sep23_s"
    },
    "cl-9k6s2t80": {
        "coupon": "9x7KYM2t",
        "currency": "clp",
        "planName": "starter_yearly_nov22_s"
    },
    "cl-9k6s2t81": {
        "coupon": "xGpmU125",
        "currency": "clp",
        "planName": "pro_yearly_sep23_s"
    },
    "co-2v7n4h10": {
        "coupon": "9x7KYM2t",
        "currency": "cop",
        "planName": "starter_yearly_nov22_s"
    },
    "co-2v7n4h11": {
        "coupon": "xGpmU125",
        "currency": "cop",
        "planName": "pro_yearly_sep23_s"
    },
    "co-73nl930": {
        "coupon": "9x7KYM2t",
        "currency": "cop",
        "planName": "starter_yearly_nov22_s"
    },
    "co-73nl931": {
        "coupon": "xGpmU125",
        "currency": "cop",
        "planName": "pro_yearly_sep23_s"
    },
    "de-1q5gh60": {
        "coupon": "9x7KYM2t",
        "currency": "eur",
        "planName": "starter_yearly_nov22_s"
    },
    "de-1q5gh61": {
        "coupon": "xGpmU125",
        "currency": "eur",
        "planName": "pro_yearly_sep23_s"
    },
    "en-1fsdff0": {
        "coupon": "9x7KYM2t",
        "currency": "usd",
        "planName": "starter_yearly_nov22_s"
    },
    "en-1fsdff1": {
        "coupon": "xGpmU125",
        "currency": "usd",
        "planName": "pro_yearly_sep23_s"
    },
    "en-jvhAlk6a0": {
        "coupon": "jvhAlk6a",
        "currency": "usd",
        "planName": "starter_yearly_nov22_s"
    },
    "en-k79uxgRQ1": {
        "coupon": "k79uxgRQ",
        "currency": "usd",
        "planName": "pro_yearly_sep23_s"
    },
    "en-euro4n0": {
        "coupon": "9x7KYM2t",
        "currency": "eur",
        "planName": "starter_yearly_nov22_s"
    },
    "en-euro4n1": {
        "coupon": "xGpmU125",
        "currency": "eur",
        "planName": "pro_yearly_sep23_s"
    },
    "en-eu-jvhAlk6a0": {
        "coupon": "jvhAlk6a",
        "currency": "eur",
        "planName": "starter_yearly_nov22_s"
    },
    "en-eu-k79uxgRQ1": {
        "coupon": "k79uxgRQ",
        "currency": "eur",
        "planName": "pro_yearly_sep23_s"
    },
    "es-imq9150": {
        "coupon": "9x7KYM2t",
        "currency": "eur",
        "planName": "starter_yearly_nov22_s"
    },
    "es-imq9151": {
        "coupon": "xGpmU125",
        "currency": "eur",
        "planName": "pro_yearly_sep23_s"
    },
    "fr-5tml2s0": {
        "coupon": "9x7KYM2t",
        "currency": "eur",
        "planName": "starter_yearly_nov22_s"
    },
    "fr-5tml2s1": {
        "coupon": "xGpmU125",
        "currency": "eur",
        "planName": "pro_yearly_sep23_s"
    },
    "gb-90la2v0": {
        "coupon": "9x7KYM2t",
        "currency": "gbp",
        "planName": "starter_yearly_nov22_s"
    },
    "gb-90la2v1": {
        "coupon": "xGpmU125",
        "currency": "gbp",
        "planName": "pro_yearly_sep23_s"
    },
    "gb-jvhAlk6a0": {
        "coupon": "jvhAlk6a",
        "currency": "gbp",
        "planName": "starter_yearly_nov22_s"
    },
    "gb-k79uxgRQ1": {
        "coupon": "k79uxgRQ",
        "currency": "gbp",
        "planName": "pro_yearly_sep23_s"
    },
    "he-olm1950": {
        "coupon": "9x7KYM2t",
        "currency": "ils",
        "planName": "starter_yearly_nov22_s"
    },
    "he-olm1951": {
        "coupon": "xGpmU125",
        "currency": "ils",
        "planName": "pro_yearly_sep23_s"
    },
    "id-7b3j9t40": {
        "coupon": "9x7KYM2t",
        "currency": "idr",
        "planName": "starter_yearly_nov22_s"
    },
    "id-7b3j9t41": {
        "coupon": "xGpmU125",
        "currency": "idr",
        "planName": "pro_yearly_sep23_s"
    },
    "in-6p8g4t90": {
        "coupon": "9x7KYM2t",
        "currency": "inr",
        "planName": "starter_yearly_nov22_s"
    },
    "in-6p8g4t91": {
        "coupon": "xGpmU125",
        "currency": "inr",
        "planName": "pro_yearly_sep23_s"
    },
    "it-5nmlo70": {
        "coupon": "9x7KYM2t",
        "currency": "eur",
        "planName": "starter_yearly_nov22_s"
    },
    "it-5nmlo71": {
        "coupon": "xGpmU125",
        "currency": "eur",
        "planName": "pro_yearly_sep23_s"
    },
    "jp-qax1510": {
        "coupon": "9x7KYM2t",
        "currency": "jpy",
        "planName": "starter_yearly_nov22_s"
    },
    "jp-qax1511": {
        "coupon": "xGpmU125",
        "currency": "jpy",
        "planName": "pro_yearly_sep23_s"
    },
    "mx-85umm40": {
        "coupon": "9x7KYM2t",
        "currency": "mxn",
        "planName": "starter_yearly_nov22_s"
    },
    "mx-85umm41": {
        "coupon": "xGpmU125",
        "currency": "mxn",
        "planName": "pro_yearly_sep23_s"
    },
    "mx-bor7410": {
        "coupon": "9x7KYM2t",
        "currency": "mxn",
        "planName": "starter_yearly_nov22_s"
    },
    "mx-bor7411": {
        "coupon": "xGpmU125",
        "currency": "mxn",
        "planName": "pro_yearly_sep23_s"
    },
    "ph-5c1k7s30": {
        "coupon": "9x7KYM2t",
        "currency": "php",
        "planName": "starter_yearly_nov22_s"
    },
    "ph-5c1k7s31": {
        "coupon": "xGpmU125",
        "currency": "php",
        "planName": "pro_yearly_sep23_s"
    },
    "ru-j48oo20": {
        "coupon": "K6WxVstS",
        "planName": "bigvu_starter_yearly_ru"
    },
    "ru-j48oo21": {
        "coupon": "XWkRx3BH",
        "planName": "bigvu_plus_yealy_ru"
    },
    "tc-52uk4-eu": {
        "coupon": "8C88jVGw",
        "planName": "euro_starter_monthly"
    },
    "tc-apif7-rub": {
        "coupon": "8C88jVGw",
        "planName": "rub_starter_monthly"
    },
    "tc-lkas3-brl": {
        "coupon": "8C88jVGw",
        "planName": "brl_starter_monthly"
    },
    "tc-sd592-usd": {
        "coupon": "8C88jVGw",
        "currency": "usd",
        "planName": "bigvu_starter_monthly_feb20"
    },
    "team0": {
        "coupon": "Ykc1t7ed",
        "currency": "usd",
        "planName": "bigvu_workgroup_yearly"
    },
    "team1": {
        "coupon": "k5Fki0o2",
        "currency": "usd",
        "planName": "bigvu_team_10users_yearly"
    },
    "BF STARTER 50%": {},
    "5tSPX0sO": {"coupon": "S78Teukq", "currency": "usd", "planName": "starter_yearly_nov22_s"},
    "fh5neRfI": {"coupon": "S78Teukq", "currency": "aed", "planName": "starter_yearly_nov22_s"},
    "cjfAVOA0": {"coupon": "S78Teukq", "currency": "aud", "planName": "starter_yearly_nov22_s"},
    "WVpLuI2F": {"coupon": "S78Teukq", "currency": "brl", "planName": "starter_yearly_nov22_s"},
    "IXCoxo3o": {"coupon": "S78Teukq", "currency": "cad", "planName": "starter_yearly_nov22_s"},
    "8zgXfLGB": {"coupon": "S78Teukq", "currency": "clp", "planName": "starter_yearly_nov22_s"},
    "RtzgFGxC": {"coupon": "S78Teukq", "currency": "cop", "planName": "starter_yearly_nov22_s"},
    "afBnSuyp": {"coupon": "S78Teukq", "currency": "eur", "planName": "starter_yearly_nov22_s"},
    "9kM6k5yW": {"coupon": "S78Teukq", "currency": "gbp", "planName": "starter_yearly_nov22_s"},
    "RrY3dL8G": {"coupon": "S78Teukq", "currency": "idr", "planName": "starter_yearly_nov22_s"},
    "EorMJ66s": {"coupon": "S78Teukq", "currency": "ils", "planName": "starter_yearly_nov22_s"},
    "GPcAWLgP": {"coupon": "S78Teukq", "currency": "inr", "planName": "starter_yearly_nov22_s"},
    "Ak4Qd8cM": {"coupon": "S78Teukq", "currency": "jpy", "planName": "starter_yearly_nov22_s"},
    "P5EG6mAL": {"coupon": "S78Teukq", "currency": "krw", "planName": "starter_yearly_nov22_s"},
    "1rdoapg7": {"coupon": "S78Teukq", "currency": "mxn", "planName": "starter_yearly_nov22_s"},
    "Ug3SNSu3": {"coupon": "S78Teukq", "currency": "myr", "planName": "starter_yearly_nov22_s"},
    "TgKec5lU": {"coupon": "S78Teukq", "currency": "pen", "planName": "starter_yearly_nov22_s"},
    "yMG3p8cr": {"coupon": "S78Teukq", "currency": "php", "planName": "starter_yearly_nov22_s"},
    "DLurellF": {"coupon": "S78Teukq", "currency": "qar", "planName": "starter_yearly_nov22_s"},
    "AepcXQEt": {"coupon": "S78Teukq", "currency": "sar", "planName": "starter_yearly_nov22_s"},
    "rk8Qdtt8": {"coupon": "S78Teukq", "currency": "try", "planName": "starter_yearly_nov22_s"},
    "6SiXeJpM": {"coupon": "S78Teukq", "currency": "uah", "planName": "starter_yearly_nov22_s"},
    "BF STARTER 40%": {},
    "FCXatAvc": {"coupon": "8uBwC1v2", "currency": "usd", "planName": "starter_yearly_nov22_s"},
    "MrilX6Tv": {"coupon": "8uBwC1v2", "currency": "aed", "planName": "starter_yearly_nov22_s"},
    "NIUmSvEd": {"coupon": "8uBwC1v2", "currency": "aud", "planName": "starter_yearly_nov22_s"},
    "WEof7FNI": {"coupon": "8uBwC1v2", "currency": "brl", "planName": "starter_yearly_nov22_s"},
    "vvHibpBP": {"coupon": "8uBwC1v2", "currency": "cad", "planName": "starter_yearly_nov22_s"},
    "Mri8vwUT": {"coupon": "8uBwC1v2", "currency": "clp", "planName": "starter_yearly_nov22_s"},
    "rwgo6p1B": {"coupon": "8uBwC1v2", "currency": "cop", "planName": "starter_yearly_nov22_s"},
    "BLm81k3B": {"coupon": "8uBwC1v2", "currency": "eur", "planName": "starter_yearly_nov22_s"},
    "H8KzwdeV": {"coupon": "8uBwC1v2", "currency": "gbp", "planName": "starter_yearly_nov22_s"},
    "DCxqbNjA": {"coupon": "8uBwC1v2", "currency": "idr", "planName": "starter_yearly_nov22_s"},
    "FI879OGe": {"coupon": "8uBwC1v2", "currency": "ils", "planName": "starter_yearly_nov22_s"},
    "ALimJnA8": {"coupon": "8uBwC1v2", "currency": "inr", "planName": "starter_yearly_nov22_s"},
    "7uQCvQLW": {"coupon": "8uBwC1v2", "currency": "jpy", "planName": "starter_yearly_nov22_s"},
    "RtaGGZwQ": {"coupon": "8uBwC1v2", "currency": "krw", "planName": "starter_yearly_nov22_s"},
    "zWPZ9SZF": {"coupon": "8uBwC1v2", "currency": "mxn", "planName": "starter_yearly_nov22_s"},
    "KzyihDht": {"coupon": "8uBwC1v2", "currency": "myr", "planName": "starter_yearly_nov22_s"},
    "nj9ubYpe": {"coupon": "8uBwC1v2", "currency": "pen", "planName": "starter_yearly_nov22_s"},
    "xpKGNgr9": {"coupon": "8uBwC1v2", "currency": "php", "planName": "starter_yearly_nov22_s"},
    "iM5Pv0ee": {"coupon": "8uBwC1v2", "currency": "qar", "planName": "starter_yearly_nov22_s"},
    "SMCDNAvn": {"coupon": "8uBwC1v2", "currency": "sar", "planName": "starter_yearly_nov22_s"},
    "iIsES7Gg": {"coupon": "8uBwC1v2", "currency": "try", "planName": "starter_yearly_nov22_s"},
    "dDsvckZj": {"coupon": "8uBwC1v2", "currency": "uah", "planName": "starter_yearly_nov22_s"},
    "BF PRO 50%": {},
    "DKfSRRY9": {"coupon": "S78Teukq", "currency": "usd", "planName": "pro_yearly_sep23_s"},
    "dhNcKvBw": {"coupon": "S78Teukq", "currency": "aed", "planName": "pro_yearly_sep23_s"},
    "kfAAsanx": {"coupon": "S78Teukq", "currency": "aud", "planName": "pro_yearly_nov22_s"},
    "xuA4nI5r": {"coupon": "S78Teukq", "currency": "brl", "planName": "pro_yearly_sep23_s"},
    "jViJiZD4": {"coupon": "S78Teukq", "currency": "cad", "planName": "pro_yearly_sep23_s"},
    "S8EfK6IP": {"coupon": "S78Teukq", "currency": "clp", "planName": "pro_yearly_sep23_s"},
    "lg5gzTL8": {"coupon": "S78Teukq", "currency": "cop", "planName": "pro_yearly_sep23_s"},
    "beePAcDZ": {"coupon": "S78Teukq", "currency": "eur", "planName": "pro_yearly_sep23_s"},
    "7oUbPKlS": {"coupon": "S78Teukq", "currency": "gbp", "planName": "pro_yearly_sep23_s"},
    "MC8srcZD": {"coupon": "S78Teukq", "currency": "idr", "planName": "pro_yearly_sep23_s"},
    "92AG7uf5": {"coupon": "S78Teukq", "currency": "ils", "planName": "pro_yearly_sep23_s"},
    "nnl5qrFi": {"coupon": "S78Teukq", "currency": "inr", "planName": "pro_yearly_sep23_s"},
    "hjZWkFlt": {"coupon": "S78Teukq", "currency": "jpy", "planName": "pro_yearly_sep23_s"},
    "d4VO3iGn": {"coupon": "S78Teukq", "currency": "krw", "planName": "pro_yearly_sep23_s"},
    "6AB8cgeC": {"coupon": "S78Teukq", "currency": "mxn", "planName": "pro_yearly_sep23_s"},
    "al7eQwYg": {"coupon": "S78Teukq", "currency": "myr", "planName": "pro_yearly_sep23_s"},
    "kOt9an3t": {"coupon": "S78Teukq", "currency": "pen", "planName": "pro_yearly_sep23_s"},
    "JXqcCST4": {"coupon": "S78Teukq", "currency": "php", "planName": "pro_yearly_sep23_s"},
    "hN9h93Vk": {"coupon": "S78Teukq", "currency": "qar", "planName": "pro_yearly_sep23_s"},
    "91Ogczx2": {"coupon": "S78Teukq", "currency": "sar", "planName": "pro_yearly_sep23_s"},
    "EYr9gdmO": {"coupon": "S78Teukq", "currency": "try", "planName": "pro_yearly_sep23_s"},
    "sHV5pJXi": {"coupon": "S78Teukq", "currency": "uah", "planName": "pro_yearly_sep23_s"},
    "BF PRO 40%": {},
    "eC5TyCY8": {"coupon": "8uBwC1v2", "currency": "usd", "planName": "pro_yearly_sep23_s"},
    "l4pwj8H1": {"coupon": "8uBwC1v2", "currency": "aed", "planName": "pro_yearly_sep23_s"},
    "eFX6XL9N": {"coupon": "8uBwC1v2", "currency": "aud", "planName": "pro_yearly_nov22_s"},
    "gaiG3dlq": {"coupon": "8uBwC1v2", "currency": "brl", "planName": "pro_yearly_sep23_s"},
    "yATuFq9o": {"coupon": "8uBwC1v2", "currency": "cad", "planName": "pro_yearly_sep23_s"},
    "jmMxEZAn": {"coupon": "8uBwC1v2", "currency": "clp", "planName": "pro_yearly_sep23_s"},
    "qFGNXt3W": {"coupon": "8uBwC1v2", "currency": "cop", "planName": "pro_yearly_sep23_s"},
    "vskxAXPa": {"coupon": "8uBwC1v2", "currency": "eur", "planName": "pro_yearly_sep23_s"},
    "cws6j5ZL": {"coupon": "8uBwC1v2", "currency": "gbp", "planName": "pro_yearly_sep23_s"},
    "kJNeAhT2": {"coupon": "8uBwC1v2", "currency": "idr", "planName": "pro_yearly_sep23_s"},
    "A90M91vH": {"coupon": "8uBwC1v2", "currency": "ils", "planName": "pro_yearly_sep23_s"},
    "p9LvBiGR": {"coupon": "8uBwC1v2", "currency": "inr", "planName": "pro_yearly_sep23_s"},
    "mQH3sote": {"coupon": "8uBwC1v2", "currency": "jpy", "planName": "pro_yearly_sep23_s"},
    "G0ZXBAH0": {"coupon": "8uBwC1v2", "currency": "krw", "planName": "pro_yearly_sep23_s"},
    "1P529Xf1": {"coupon": "8uBwC1v2", "currency": "mxn", "planName": "pro_yearly_sep23_s"},
    "wruZUAcd": {"coupon": "8uBwC1v2", "currency": "myr", "planName": "pro_yearly_sep23_s"},
    "36YpwNiU": {"coupon": "8uBwC1v2", "currency": "pen", "planName": "pro_yearly_sep23_s"},
    "2sAyd2Hg": {"coupon": "8uBwC1v2", "currency": "php", "planName": "pro_yearly_sep23_s"},
    "MaBI5Z8T": {"coupon": "8uBwC1v2", "currency": "qar", "planName": "pro_yearly_sep23_s"},
    "CshG2P9C": {"coupon": "8uBwC1v2", "currency": "sar", "planName": "pro_yearly_sep23_s"},
    "eCZVt5sM": {"coupon": "8uBwC1v2", "currency": "try", "planName": "pro_yearly_sep23_s"},
    "5w7ReEKa": {"coupon": "8uBwC1v2", "currency": "uah", "planName": "pro_yearly_sep23_s"},

    "JBfcusd0": {
        "coupon": "nBpfU195",
        "currency": "usd",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcusd1": {
        "coupon": "nCpfM255",
        "currency": "usd",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcaed0": {
        "coupon": "nBpfU195",
        "currency": "aed",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcaed1": {
        "coupon": "nCpfM255",
        "currency": "aed",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcaud0": {
        "coupon": "nBpfU195",
        "currency": "aud",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcaud1": {
        "coupon": "nCpfM255",
        "currency": "aud",
        "planName": "pro_yearly_nov22_s"
    },
    "JBfcbrl0": {
        "coupon": "nBpfU195",
        "currency": "brl",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcbrl1": {
        "coupon": "nCpfM255",
        "currency": "brl",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfccad0": {
        "coupon": "nBpfU195",
        "currency": "cad",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfccad1": {
        "coupon": "nCpfM255",
        "currency": "cad",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcclp0": {
        "coupon": "nBpfU195",
        "currency": "clp",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcclp1": {
        "coupon": "nCpfM255",
        "currency": "clp",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfccop0": {
        "coupon": "nBpfU195",
        "currency": "cop",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfccop1": {
        "coupon": "nCpfM255",
        "currency": "cop",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfceur0": {
        "coupon": "nBpfU195",
        "currency": "eur",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfceur1": {
        "coupon": "nCpfM255",
        "currency": "eur",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcgbp0": {
        "coupon": "nBpfU195",
        "currency": "gbp",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcgbp1": {
        "coupon": "nCpfM255",
        "currency": "gbp",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcidr0": {
        "coupon": "nBpfU195",
        "currency": "idr",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcidr1": {
        "coupon": "nCpfM255",
        "currency": "idr",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcils0": {
        "coupon": "nBpfU195",
        "currency": "ils",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcils1": {
        "coupon": "nCpfM255",
        "currency": "ils",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcinr0": {
        "coupon": "nBpfU195",
        "currency": "inr",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcinr1": {
        "coupon": "nCpfM255",
        "currency": "inr",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcjpy0": {
        "coupon": "nBpfU195",
        "currency": "jpy",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcjpy1": {
        "coupon": "nCpfM255",
        "currency": "jpy",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfckrw0": {
        "coupon": "nBpfU195",
        "currency": "krw",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfckrw1": {
        "coupon": "nCpfM255",
        "currency": "krw",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcmxn0": {
        "coupon": "nBpfU195",
        "currency": "mxn",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcmxn1": {
        "coupon": "nCpfM255",
        "currency": "mxn",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcmyr0": {
        "coupon": "nBpfU195",
        "currency": "myr",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcmyr1": {
        "coupon": "nCpfM255",
        "currency": "myr",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcpen0": {
        "coupon": "nBpfU195",
        "currency": "pen",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcpen1": {
        "coupon": "nCpfM255",
        "currency": "pen",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcphp0": {
        "coupon": "nBpfU195",
        "currency": "php",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcphp1": {
        "coupon": "nCpfM255",
        "currency": "php",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcqar0": {
        "coupon": "nBpfU195",
        "currency": "qar",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcqar1": {
        "coupon": "nCpfM255",
        "currency": "qar",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcsar0": {
        "coupon": "nBpfU195",
        "currency": "sar",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcsar1": {
        "coupon": "nCpfM255",
        "currency": "sar",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfctry0": {
        "coupon": "nBpfU195",
        "currency": "try",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfctry1": {
        "coupon": "nCpfM255",
        "currency": "try",
        "planName": "pro_yearly_sep23_s"
    },
    "JBfcuah0": {
        "coupon": "nBpfU195",
        "currency": "uah",
        "planName": "starter_yearly_nov22_s"
    },
    "JBfcuah1": {
        "coupon": "nCpfM255",
        "currency": "uah",
        "planName": "pro_yearly_sep23_s"
    }
}

/**
 * Extra event data for the current campaign
 */
export const currentCampaignEventData = {}